@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    background-color: #f6f6f6;
    color: var(--primary-text);
    font-family: 'Inter', sans-serif;
    line-height: 1.5;
    position: relative;
}

html {
    font-size: 14px; /* Default for small to medium screens */
}

@media (min-width: 1366px) and (max-width: 1919px) {
    html {
        font-size: 16px; /* Medium to large screens */
    }
}

@media (min-width: 1920px) {
    html {
        font-size: 18px; /* Extra large screens */
    }
}

@media (min-width: 2200px) {
    .extraSize {
        height: 100vh;
    }
}
