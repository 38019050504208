:root {
    &,
    &.light-mode {
        --miner-title: #303b47;
        --miner-description: #374151;
        --miner-secondary: rgba(64, 64, 64, 0.6);
        --primary-input: #171717;
        --secondary-input: rgba(64, 64, 64, 0.6);
        --tertiary-input: rgba(64, 64, 64, 0.3);
        --third-gray: #737373;
        --second-gray: #fafafa;
        --primary-bg: rgba(0, 0, 0, 0.06);
        --primary-text: #585757;
        --disabled-color: rgba(163, 163, 163, 0.16);
        --secondary-text: #969696;
        --text-bold: rgba(28, 28, 28, 1);
        --light-black: #1c1c1c;
        --primary-color: #0085ff;
        --tag-color: #5b6677;
        --text-upload: #40404099;
        --primary-color-hover: #0085dd;
        --wallet-blue: #5029e9;
        --secondary-bg: #e1d8c7;
        --secondary-color: rgba(255, 108, 71, 1);
        --secondar-colorL: rgb(245, 192, 179);
        --border-color: #e8e8e8;
        --backdrop-filter-color: rgba(0, 0, 0, 0.3);
        --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.04);
        --shadow-md: 0px 0.6rem 2.4rem rgba(0, 0, 0, 0.06);
        --shadow-lg: 0 2.4rem 3.2rem rgba(0, 0, 0, 0.12);
        --alert-green: #10b981;
        --alert-red: #ef4444;
    }

    &.dark-mode {
        --primary-color: #0f172a;
        --primary-color2: #1e293b;
        --secondary-color: #f1f5f9;
        --secondary-color2: #e2e8f0;
        --tertiary-color: #fef08a;
        --shadow-sm: 0 1px 2px rgba(255, 255, 255, 0.4);
        --shadow-md: 0px 0.6rem 2.4rem rgba(255, 255, 255, 0.1);
        --shadow-lg: 0 2.4rem 3.2rem rgba(255, 255, 255, 0.4);
    }
}
.scrollableCards::-webkit-scrollbar {
    height: 0px;
}

.modal::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

.modal::-webkit-scrollbar-thumb,
.modal::-webkit-scrollbar-track {
    border-radius: 92px;
}

.modal::-webkit-scrollbar-thumb {
    background: var(--primary-bg);
}

.modal::-webkit-scrollbar-track {
    background: white;
}

.container {
    perspective: 1000px;
}

.card {
    transition: transform 1500ms;
    transform-style: preserve-3d;
}
.container:hover > .card {
    transform: rotateY(180deg);
}
.front {
    cursor: pointer;
}
.back,
.front {
    backface-visibility: hidden;
}

.back {
    transform: rotateY(180deg);
}

.main::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

.main::-webkit-scrollbar-thumb,
.main::-webkit-scrollbar-track {
    border-radius: 92px;
}

.main::-webkit-scrollbar-thumb {
    background: var(--primary-bg);
}

.main::-webkit-scrollbar-track {
    background: white;
}

/* Hide number input arrows in WebKit browsers (Chrome, Safari) */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide number input arrows in Firefox */
/* input[type='number'] {
    -moz-appearance: textfield;
} */

/* Hide number input arrows in Edge */
input[type='number'] {
    appearance: textfield;
}

.hideOnSomePage {
    display: none;
}

.modal-sig {
    display: flex; /* Use flex to center the modal */
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
}
.wallet-adapter-dropdown {
    display: flex !important; /* Override to flex display */
    align-items: center !important; /* Center items vertically */
}
.modal-content-sig {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px; /* Adjust as needed */
    border-radius: 5px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* .ql-bubble .ql-tooltip.ql-editing {
    z-index: 9999; 
    position: absolute; 
} */

/* Style for the tooltip that appears when text is highlighted */

/* Increase width of the ReactQuill toolbar */
/* Increase the size of the tooltip container */

/* Increase the size of the input field inside the tooltip */

/* Optional: Style the tooltip buttons */

.ql-toolbar {
    width: 100%; /* Make toolbar full width of the editor */
    min-width: 200px; /* Or set a specific width, e.g., 800px */
    margin: 0 auto; /* Center the toolbar if not full width */
}
.ql-container.ql-bubble:not(.ql-disabled) a::before,
.ql-container.ql-bubble:not(.ql-disabled) a::after {
    display: none !important;
}
.ql-tooltip {
    background-color: white !important; /* Change tooltip background color */
    color: black !important; /* Ensure tooltip text is visible */
    border: 1px solid #ddd; /* Optional: Adds a subtle border */
    z-index: 9999;
    width: 300px !important; /* Set a larger width for the tooltip */
    height: auto; /* Allow tooltip height to adjust as needed */
    padding: 8px 12px !important ; /* Add padding for more spacing */
    top: -55px !important; /* Adjust positioning slightly */
    /* left: -10px !important; */
    transform: translateY(-100%) !important; /* Move tooltip above the highlighted text */
    transform: translateX(45%) !important;
    border-radius: 4px !important; /* Optional: make corners more rounded */
    box-shadow: 0px 2px 16.8px 0px #00000026 !important;
    font-size: 14px !important ;
}

/* Position tooltip above highlighted text */
.ql-tooltip[data-mode='link'] {
    transform: translateY(-100%) !important; /* Move tooltip above the highlighted text */
    top: -55px !important; /* Adjust positioning slightly */
    transform: translateX(45%) !important;
}

.ql-bubble .ql-tooltip-editor input[type='text'] {
    width: 90%; /* Make input field full width within the tooltip */
    color: var(--primary-input);
    padding: 8px; /* Add padding for better usability */
    font-size: 16px; /* Increase font size */
    border: 1px solid #e5e5e5 !important; /* Define border for input */
    border-radius: 4px; /* Optional: rounded corners */
    box-sizing: border-box; /* Ensure padding does not affect width */
    background-color: transparent;
    font-size: 14px !important;
    right: 12px;
    height: auto !important;
}
.ql-bubble .ql-tooltip-editor input[type='text']::placeholder {
    color: #a1a1aa !important;
    font-size: 14px;
    line-height: 24px;
}

.ql-bubble .ql-tooltip-arrow {
    border-left: 6px solid transparent !important;
    border-right: 6px solid transparent !important;
    border-top: 6px solid #fff !important; /* Gray color for downward arrow */
    border-bottom: none !important;
    content: ' ' !important;
    display: block !important;

    left: 12px !important;
    margin-left: -6px !important;
    position: absolute !important;
    top: 100% !important; /* Position the arrow below the tooltip */
}

/* Ensures the button and SVG icon inside .ql-link stay black */
.ql-toolbar .ql-link,
.ql-toolbar .ql-link svg,
.ql-toolbar .ql-link svg path,
.ql-toolbar .ql-link svg line {
    color: gray !important; /* Forces the text/icon color to black */
    fill: #fff !important; /* Ensures SVG icon fill is black */
    stroke: gray !important; /* Applies black to SVG strokes, if any */
    background-color: transparent !important; /* Keeps background transparent */
}

/* Override hover, focus, and active states */

.ql-toolbar .ql-link:hover,
.ql-toolbar .ql-link:hover svg,
.ql-toolbar .ql-link:hover svg path,
.ql-toolbar .ql-link:hover svg line {
    color: gray !important;
    fill: #fff !important;
    stroke: var(--tertiary-input) !important;
    background-color: transparent !important; /* Prevents background color on hover */
}

.ql-toolbar .ql-link:focus,
.ql-toolbar .ql-link:focus svg,
.ql-toolbar .ql-link:focus svg path,
.ql-toolbar .ql-link:focus svg line {
    color: gray !important;
    fill: #fff !important;
    stroke: gray !important;
    background-color: transparent !important; /* Prevents background color on hover */
}

.ql-toolbar .ql-link:active,
.ql-toolbar .ql-link:active svg,
.ql-toolbar .ql-link:active svg path,
.ql-toolbar .ql-link:active svg line {
    color: gray !important;
    fill: #fff !important;
    stroke: gray !important;
    background-color: transparent !important; /* Prevents background color on hover */
}

/* Optional: Customize the tooltip button */
/* .ql-tooltip a.ql-action::after {
    content: 'Apply';
    font-size: 14px;
    color: white;
    background-color: #28a745;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.ql-tooltip a.ql-remove::after {
    content: 'Cancel';
    font-size: 14px;
    color: white;
    background-color: #dc3545;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 5px;
} */

.ql-tooltip a.ql-action::after,
.ql-tooltip a.ql-remove::after {
    padding: 6px 12px; /* Increase padding for buttons */
    font-size: 14px; /* Increase font size for readability */
}

.ql-editor.ql-blank::before {
    font-style: normal; /* Remove italic */
    color: var(--tertiary-input) !important; /* Optional: Customize the placeholder color */
    font-family: inherit !important; /* Ensure it inherits the same font-family */

    left: 0px !important;
    right: 0px !important;
}

/* Target the tooltip that shows the link on hover */

.ql-editor {
    min-height: 100px;
    padding-top: 8px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 8px;
    border-radius: 4px; /* Optional: Rounded corners */
    font-size: 16px;
    color: var(--primary-input);
}
.instructional-text .ql-editor {
    min-height: 70px; /* Ensure the editor has a minimum height */
    padding-top: 8px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 8px;
    border-radius: 4px; /* Optional: Rounded corners */
    font-size: 16px;
    /* font-weight: 600; */
    font-family: inherit;
    color: var(--secondary-input) !important;
}
.wallet-adapter-button-end-icon,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon img {
    width: 20px !important;
    height: 20px !important;
}

.wallet-adapter-dropdown-list .wallet-adapter-dropdown-list-item:nth-child(2) {
    display: none !important;
}

/* Set the background color of the dropdown to white */
.wallet-adapter-dropdown-list {
    background-color: white !important;
    width: 173px !important;
    border-radius: 8px; /* Optional: Adds rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important; /* Optional: Adds a subtle shadow */
}

/* Set the text color of the dropdown items to gray */
.wallet-adapter-dropdown-list-item {
    color: var(--primary-text) !important;
}

/* Optional: Hover effect to change background and text color */
.wallet-adapter-dropdown-list-item:hover {
    background-color: #f0f0f0 !important; /* Light gray background on hover */
}

.react-datepicker__header {
    background-color: white !important;
    border-bottom: 0px !important;
    padding-top: 16px !important;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
    top: 10px !important;
}
/* right arrow button */
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 0px !important;
}

/*upward tip*/
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    display: none !important;
}

.react-datepicker-popper {
    max-height: 500px !important;
}
.react-datepicker {
    display: flex !important;
    flex-direction: column !important;
    border: 1px solid #e8e8e8 !important;
    border-radius: 8px;
    overflow: hidden !important;
    left: 80% !important;
    box-shadow: 0px 6px 10px 0px #00000014 !important;
    /* box-shadow: 0px 6px 10px 0px #65020214; */
    /* box-shadow: 0px 1px 18px 0px #0000000f;

    box-shadow: 0px 3px 5px 0px #0000001a; */
}
.react-datepicker__current-month {
    color: var(--light-black) !important; /* Replace with your desired color */
    font-weight: 500 !important; /* Optional: make the text bold */
}
.react-datepicker__time-container {
    width: 100% !important;
    border-left: 0 !important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    height: 100% !important ;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100% !important;
    height: 80px !important;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box::-webkit-scrollbar {
    height: 6px !important;
    width: 6px !important;
}
/*hide time text*/
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    display: none !important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box::-webkit-scrollbar-thumb,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box::-webkit-scrollbar-track {
    border-radius: 92px !important;
}

.react-datepicker__day-name {
    color: var(--secondary-text) !important;
}

.react-datepicker__day,
.react-datepicker__time-name {
    color: var(--primary-text) !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box::-webkit-scrollbar-thumb {
    background: var(--primary-bg) !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box::-webkit-scrollbar-track {
    background: white !important;
}

.react-datepicker__day--selected {
    background-color: var(--primary-color) !important; /* Replace with your preferred color */
    color: #ffffff !important; /* Text color */
}

/* Custom styles for the selected time */
.react-datepicker__time-list-item--selected {
    background-color: var(--primary-color) !important; /* Replace with your preferred color */
    color: #ffffff; /* Text color */
}

.react-datepicker__day:hover {
    background-color: var(--primary-color) !important; /* Your preferred hover color */
    color: #ffffff !important;
}

/* Hover styles for time */
.react-datepicker__time-list-item:hover {
    background-color: var(--primary-color) !important;
    color: #ffffff !important;
}

/* Target scrollbar within the time list of react-datepicker */
.react-datepicker__time-list::-webkit-scrollbar {
    width: 6px !important; /* Width of the scrollbar */
}

.react-datepicker__time-list::-webkit-scrollbar-thumb {
    background: var(--primary-bg) !important; /* Color for the scrollbar thumb */
    border-radius: 92px !important; /* Rounded corners */
}

.react-datepicker__time-list::-webkit-scrollbar-track {
    background: white !important; /* Color for the scrollbar track */
    border-radius: 92px !important; /* Rounded corners */
}

/* Firefox scrollbar styling */
.react-datepicker__time-list {
    scrollbar-width: thin !important; /* Sets a thinner scrollbar width for Firefox */
    scrollbar-color: var(--primary-bg) white !important; /* Thumb and track color */
}

/* custokm tip here */
.grid-masonry {
    display: grid;
    background-color: red;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, minmax(0, 1fr)); /* 2 columns, adjust if needed */
    gap: 1rem; /* Adjust the gap as per your design, 1rem is the same as `gap-4` in Tailwind */
}

.mobile-css {
    background: linear-gradient(89.64deg, #ffffff 0.34%, #f6f3e5 130.17%);
}
